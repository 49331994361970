import * as React from 'react';
import { logging } from '@inwink/logging';
import type { VisualTheme } from '@inwink/entities/visualtheme';
import { blocsCatalog } from './blocscatalog';
import type { IDynamicBlocBaseProps } from './common';
import { BlocNotFound } from './blocs/bloc.notfound';
import type { IDynamicPageBlocContentProps } from './dynamicpagebloc.props';
import { withUrlService } from '../urlstatecontext';

const logger = logging.getLogger("UI");

export const ContentTemplateContext = React.createContext<VisualTheme.IBlocContentTemplateBase>(null);

@withUrlService()
class DynamicPageBlocContentControl extends React.Component<IDynamicPageBlocContentProps, any> {
    shouldComponentUpdate(nextprops: IDynamicPageBlocContentProps) {
        if (this.props.template !== nextprops.template) return true;

        if (this.props.page !== nextprops.page) return true;
        if (this.props.visualstate !== nextprops.visualstate) return true;
        if (nextprops.blocState !== this.props.blocState) return true;
        if (nextprops.location !== this.props.location) return true;
        if (nextprops.event !== this.props.event) return true;
        if (nextprops.community !== this.props.community) return true;
        if (nextprops.i18n !== this.props.i18n) return true;
        if (nextprops.user.currentUser !== this.props.user.currentUser) return true;
        if (nextprops.visualConfiguration !== this.props.visualConfiguration) return true;
        if (nextprops.editable !== this.props.editable) return true;

        logger.verbose("no re-render for bloc " + this.props.contenttemplate.id);
        return false;
    }

    updateBloc = (blocstate: any) => {
        const contenttemplate = this.props.contenttemplate;
        const page = this.props.page;
        this.props.pageActions.setBlocData(page.id, page.pagelock, contenttemplate.id, blocstate);
    };

    render() {
        if (this.props.ssr?.firstPass) {
            return true;
        }

        const contenttemplate = this.props.contenttemplate;
        let component = this.props.customComponent;
        const componentDef = blocsCatalog[contenttemplate.type];
        if (!component) {
            if (!componentDef) {
                component = BlocNotFound;
            } else {
                component = componentDef[this.props.kind];
            }
        }

        if (component) {
            const page = this.props.page;
            const blocState = this.props.blocState;
            const themePreset = this.props.bloctemplate.themePreset;
            const newBlocThemes = this.props.visualConfiguration?.newBlocThemes;
            let theme = this.props.theme;
            // Pour appliquer la config du thème custom quand il y en a un
            if (themePreset && newBlocThemes?.length) {
                const index = newBlocThemes.findIndex((newtheme) => {
                    return newtheme.id === themePreset;
                });

                if (index !== -1) {
                    theme = newBlocThemes[index];
                }
            }

            const props: IDynamicBlocBaseProps = {
                id: contenttemplate.id,
                page: page,
                event: this.props.event,
                community: this.props.community,
                rootwebsite: this.props.rootwebsite,
                user: this.props.user,
                i18n: this.props.i18n,
                urlservice: this.props.urlservice,
                theme: theme,
                customContentCSSClass: componentDef ? componentDef.customCSSClass : "",
                bloctemplate: this.props.bloctemplate,
                template: contenttemplate,
                blocState: blocState,
                location: this.props.location,
                history: this.props.history,
                match: this.props.match,
                datacontext: this.props.datacontext,
                pageActions: this.props.pageActions,
                pagefooter: this.props.pagefooter,
                update: this.updateBloc,
                customcontext: this.props.customcontext,
                editable: this.props.editable,
                patchContent: this.props.patchContent,
                visualstate: this.props.visualstate,
                onLayoutInit: this.props.onLayoutInit,
                onPageLoading: this.props.onPageLoading,
                workspaceData: this.props.workspaceData,
                visualConfiguration: this.props.visualConfiguration
            };
            return <ContentTemplateContext.Provider value={this.props.contenttemplate}>{
                React.createElement(component, props)
            }</ContentTemplateContext.Provider>;
        }

        return <div className="empty" />;
    }
}

// eslint-disable-next-line import/no-mutable-exports
export let DynamicPageBlocContent = DynamicPageBlocContentControl;
export function hookDynamicPageBlocContent(callback) {
    DynamicPageBlocContent = callback(DynamicPageBlocContentControl);
}
