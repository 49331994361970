import * as React from 'react';
import { withI18nHelper } from '@inwink/i18n/reactcontext';
import { registerItemTemplatePart } from '@inwink/itemtemplate/templatecatalog';
import type { Entities } from '@inwink/entities/entities';
import type { VisualTheme } from '@inwink/entities/visualtheme';
import { getPredicate } from '@inwink/expressions';
import { displayContent } from '@@services/itemshelpers';
import type { States } from '@@services/services';
import { ContentTemplateContext } from '@@pages/dynamicpagebloc.content';
import { checkIfShownByDate } from "@@pages/helpers";
import type { ItemTemplateProps } from './itemtemplate.props';
import { AppLink } from '../applink';
import { connect } from 'react-redux';

import "./bloccontentactions.less";

const BadgeDownload = React.lazy(() => import("../badgedownload"));
const EntityDetailActions = React.lazy(() => import(
    "@@pages/entitydetail/entitydetail.actions"
));
const CalendarEventGenerator = React.lazy(() => import(
    "@@event/components/calendargenerator.event"
));

export interface IBlocContentActionsItemsProps extends ItemTemplateProps {
    i18n: States.i18nState;
    event: States.IEventState;
    community: States.ICommunityState;
}
export interface IBlocContentActionsItemsState {
    initialized: boolean;
}
@withI18nHelper()
class BlocContentActionsComponent extends React.Component<IBlocContentActionsItemsProps, IBlocContentActionsItemsState> {
    constructor(props: IBlocContentActionsItemsProps) {
        super(props);
        this.state = {
            initialized: false
        };
    }

    componentDidMount(): void {
        if (!this.state.initialized){
            this.setState({ initialized : true });
        }
    }

    private filterActionItem(it, event: States.IEventState, datacontext: Entities.IPageDataContext) {
        const item = it;
        let show = true;
        if (item.showIf) {
            if (!(item as any).showIfPredicate) {
                const pr = getPredicate(item.showIf);
                (item as any).showIfPredicate = pr;
            }
            show = (item as any).showIfPredicate(Object.assign({}, this.props.datacontext, {
                entity: this.props.data
            }));
        }

        if (show && item.showblocbydate && event.detail && !InWinkPreview) {
            show = checkIfShownByDate(item, event, datacontext) as boolean;
        }
        
        if (show && datacontext.communitystate && datacontext.entity?._hasPartialContent) {
            show = false;
        }

        return show;
    }

    renderAction = (a, i) => {
        const i18n = this.props.i18nHelper;
        const text: any = i18n.translateBag(a.label || a.title);
        const child = text;
        let title;
        const icon = a.icon && a.icon.className ? <i className={"icon " + a.icon.className} /> : undefined;
        const actionType = a.link && (a.link as VisualTheme.IAppLink).action
            ? (a.link as VisualTheme.IAppLink).action : null;
        const classNames = [];
        
        if (a.id) {
            classNames.push("act-" + a.id);
        }

        if (a.showblocbydate && InWinkPreview) {
            const filterType = checkIfShownByDate(a, this.props.event, this.props.datacontext, true);
            if (filterType) {
                classNames.push("hidden");
                if ((filterType as any).dates) {
                    title = i18n.translate("actions.filterbydate.dates", { dates: (filterType as any).dates });
                } else {
                    title = i18n.translate(`actions.filterbydate.${filterType}`);
                }
            }
        }

        if (a.displayOptions) {
            if (a.displayOptions.isSecondary) {
                classNames.push("lightbtn");
            }
        }

        if (a.disabled) {
            return null;
        }

        if (a?.viewports?.length) {
            classNames.push("has-viewport");
            a.viewports.forEach((viewport) => {
                classNames.push(`display-on-${viewport}`);
            });
        }

        if (actionType && (actionType === "addEventToCalendar" || actionType === "addSessionsToCalendar")) {
            if (!this.state.initialized) {
                return <React.Fragment key={'a.link' + i} />;
            }
            return <React.Suspense fallback={<EmptyLink />}>
                <CalendarEventGenerator
                    title={a.label}
                    role="button"
                    key={'a.link' + i}
                    page={this.props.page}
                    type={(a.link as VisualTheme.IAppLink).action}
                    i18n={this.props.i18n}
                    event={this.props.event}
                    tooltip={title}
                    icon={icon}
                />
            </React.Suspense>;
        } if (actionType && actionType === "downloadBadge") {
            if (!this.state.initialized) {
                return <React.Fragment key={'a.link' + i} />;
            }
            return <React.Suspense fallback={<EmptyLink />}>
                <BadgeDownload
                    className={classNames.join(" ")}
                    role="button"
                    key={'cal' + i}
                    title={a.label}
                    type={(a.link as VisualTheme.IAppLink).action}
                    i18n={this.props.i18n}
                    icon={icon}
                />
            </React.Suspense>;
        } if (a.link?.action
            && (
                this.props.template.type === "itemtemplateactions"
                || this.props.template.type === "entitydetailv2actions")
        ) {
            if (!this.state.initialized) {
                return <React.Fragment key={'a.link' + i} />;
            }

            return <React.Suspense
                key={'a.link' + i}
                fallback={<EmptyLink />}
            >
                <EntityDetailActions
                    datacontext={this.props.datacontext}
                    page={this.props.page}
                    event={this.props.event || this.props.datacontext.eventstate}
                    rootwebsite={(this.props.datacontext as any).rootwebsite}
                    user={{currentUser: this.props.user}}
                    location={(this.props.datacontext as any).location}
                    actionClassName={classNames.join(" ")}
                    i18nHelper={i18n}
                    i18n={this.props.i18n}
                    entityId={this.props.data?.id}
                    entityKind={this.props?.dataKind
                                || this.props.page?.context?.entityKind || this.props?.datacontext?.entityKind}
                    action={a}
                    visualstate={null}
                    pageActions={null}
                    blocState={null}
                    bloctemplate={null}
                    update={null}
                    urlservice={null}
                    theme={null}
                    template={null}
                    secondaryEntityId={this.props.data.id}
                />
            </React.Suspense>;
        }

        return <AppLink
            role="button"
            key={'a.link' + i}
            link={a.link as any}
            datacontext={{ entity: this.props.data}}
            className={classNames.join(" ")}
            title={title}
        >
            {icon}
            {child}
        </AppLink>;
    };

    renderContent(monbloc: VisualTheme.IBlocContentTemplateBase | VisualTheme.IItemTemplate) {
        let actionsElts;
        let bloc = monbloc;
        if (this.props.template?.type === "itemtemplateactions") {
            bloc = this.props.template;
        }
        if (bloc.actions && bloc.actions.length) {
            actionsElts = bloc.actions.filter(
                (a) => a.disabled != true && displayContent({ currentUser: this.props.user }, {
                    event: this.props.event,
                    community: this.props.community, // this.props.community,
                    requireConnexion: a.requireConnexion,
                    requireRegistration: a.requireRegistration,
                    requireMembership: a.requireMembership,
                    membershipActive: a.membershipActive,
                    membershipLevelIds: a.membershipLevelIds
                })
                    && this.filterActionItem(a, this.props.event, this.props.datacontext)
            ).map(this.renderAction);

            return <div className="itembloccontentactions">
                {actionsElts}
            </div>;
        }

        return <div className="itembloccontentactions no-actions" />;
    }

    render() {
        return <ContentTemplateContext.Consumer>
            {(template) => this.renderContent(template)}
        </ContentTemplateContext.Consumer>;
    }
}

function mapBlocStateToProps(state: States.IAppState) {
    return {
        event: state.event,
        community: state.community
    };
}

function mapBlocDispatchToProps() {
    return {
    };
}

export const BlocContentActions: new (any)
=> React.Component<any, any> = connect(mapBlocStateToProps, mapBlocDispatchToProps)(BlocContentActionsComponent as any) as any;

registerItemTemplatePart("entitydetailv2actions", BlocContentActions);
registerItemTemplatePart("itemtemplateactions", BlocContentActions);
registerItemTemplatePart("bloccontentactions", BlocContentActions);

function EmptyLink() {
    return <a />;
}
